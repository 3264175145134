// @ts-ignore
import MmenuLight from 'mmenu-light';

export default function init() {
    const menu = new MmenuLight(
        document.querySelector( '#mobile-menu-nav' ),
        "(max-width: 1023px)"
    );

    const navigator = menu.navigation({
        // options
        // Default options ['light', 'dark']
        theme: 'light',
        title: ''
    });

    const drawer = menu.offcanvas({
        // options
        position: 'left'
    });

    const trigger = document.querySelector( '[data-mobile-menu-trigger]' ) as HTMLElement

    if(trigger) {
        trigger.addEventListener( "click", ( event ) => {
            event.preventDefault();
            drawer.open();
        });
    }

}
